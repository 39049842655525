import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // Use react-router-dom or your preferred routing library

import {useKeycloakHook} from "globalState";

import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import SoftBox from "components/SoftBox";


import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import ipRangeCheck from 'ip-range-check';



const CallbackComponent = () => {
	
    const ipRange=['195.145.26.16/28','91.235.236.0/24','193.28.56.0/24','149.249.8.0/24','213.221.106.247'];
	
	const navigate=useNavigate();

	  //create and send session and user data to dataabase
	  const createUserSession = async (userid,sessionid,firstname,lastname,email) => {
		  
		 
		 fetch(window.env.API_URL + "/v1/user/createsession", {
			  method: 'POST',
			  headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			  },
			  body: new URLSearchParams({
					'userid': userid,
					'sessionid':sessionid,
					'firstname':firstname,
					'lastname':lastname,
					'email':email
				})
			})
			.then((res)=>{
				
				if (res.ok) {
					res.json().then(data => {
						sessionStorage.setItem("session_userid", data.userid);
					})
					navigate('/dashboard');
				}
			})
			.catch((error)=>console.error('Error:', error.message))	
		};
	  

	
	const keycloak  = useKeycloakHook();
	

	
	const [open, setOpen] = React.useState(false);
	  
	  
	const handleDialogClose = () => {
		setOpen(false);
		keycloak.logout();	  
	};
	  
	const handleDialogOpen = () => {
		setOpen(true)
	}
	
	const handleLogoutComponent=()=>{
		return(
			<Dialog
			  open={open}
			>
			  <DialogContent>
				<DialogContentText id="alert-dialog-description">
				  Sie sind nicht berechtigt, diese Anwendung zu nutzen. Bitte schließen Sie die Seite.
				</DialogContentText>
			  </DialogContent>
			  <DialogActions>
				<Button onClick={handleDialogClose} autoFocus>
				  Schließen
				</Button>
			  </DialogActions>
			</Dialog>
		)
	  }

	const devCondition=true;
	
	useEffect(()=>{
		if(process.env.NODE_ENV === 'development'){// login procedure for dev env
			if(devCondition){
				keycloak.init({
					   url: window.env.AUTH_URL,
					   onLoad: 'check-sso',
					   realm: 'ruv-emp',
					   clientId: 'spa-vmfk',
					   pkceMethod: 'S256',
					   KeycloakResponseType: 'code',
					   checkLoginIframe: false,
					   scope:'openid profile email',
					   redirectUri: window.env.AUTH_REDIRECT_URI
				   }).then((authenticated) => {
					   if (authenticated) {
								  sessionStorage.setItem("session_key", keycloak.idTokenParsed.session_state);
								  sessionStorage.setItem("session_xvuser", keycloak.idTokenParsed.preferred_username);
								  sessionStorage.setItem("session_user", keycloak.idTokenParsed.given_name + ' ' + keycloak.idTokenParsed.family_name);
								  sessionStorage.setItem("session_useremail", keycloak.idTokenParsed.email);
								  createUserSession(keycloak.idTokenParsed.preferred_username,keycloak.idTokenParsed.session_state,keycloak.idTokenParsed.given_name,keycloak.idTokenParsed.family_name,keycloak.idTokenParsed.email);	
								  
						  } else {
							  keycloak.login();
						  }
				   })
			}else{
				// Replace the `/hooks/authentification` route with `/` 
				 // window.history.pushState(null, null, '/');
				 window.location.href = 'https://www.ruv.de/';
			}
			
		} else { //login procedure for staging and prod envs
				fetch(window.env.API_URL + "/v1/identify")
				.then(resp=>resp.text())
				.then(resp=>{
					const trimmedText=resp.trim();
				 if(ipRangeCheck(trimmedText,ipRange)){
					 keycloak.init({
							url: window.env.AUTH_URL,
							onLoad: 'check-sso',
							realm: 'ruv-emp',
							clientId: 'spa-vmfk',
							pkceMethod: 'S256',
							KeycloakResponseType: 'code',
							checkLoginIframe: false,
							scope:'openid profile email',
							redirectUri: window.env.AUTH_REDIRECT_URI
						}).then((authenticated) => {
							if (authenticated) {
								const groups= keycloak.idTokenParsed['http://ruv.de/groups']
								if(groups.includes('firmenversorgkonz_verwalter')){
									sessionStorage.setItem("session_key", keycloak.idTokenParsed.session_state);
									sessionStorage.setItem("session_xvuser", keycloak.idTokenParsed.preferred_username);
									sessionStorage.setItem("session_user", keycloak.idTokenParsed.given_name + ' ' + keycloak.idTokenParsed.family_name);
									sessionStorage.setItem("session_useremail", keycloak.idTokenParsed.email);
									sessionStorage.setItem("session_http://ruv.de/groups", groups);
									createUserSession(keycloak.idTokenParsed.preferred_username,keycloak.idTokenParsed.session_state,keycloak.idTokenParsed.given_name,keycloak.idTokenParsed.family_name,keycloak.idTokenParsed.email);
								}else if(groups.includes('firmenversorgkonz_user')){
									sessionStorage.setItem("session_key", keycloak.idTokenParsed.session_state);
									sessionStorage.setItem("session_xvuser", keycloak.idTokenParsed.preferred_username);
									sessionStorage.setItem("session_user", keycloak.idTokenParsed.given_name + ' ' + keycloak.idTokenParsed.family_name);
									sessionStorage.setItem("session_useremail", keycloak.idTokenParsed.email);
									sessionStorage.setItem("session_http://ruv.de/groups", groups);
									createUserSession(keycloak.idTokenParsed.preferred_username,keycloak.idTokenParsed.session_state,keycloak.idTokenParsed.given_name,keycloak.idTokenParsed.family_name,keycloak.idTokenParsed.email);
								}else{
									handleDialogOpen(); 
								}
							
							} else {
								keycloak.login();
							}
						});		 
				 }else{ // redirect to 'https://www.ruv.de/'; if ip is not amongts authorised ips
				    // Replace the `/hooks/authentification` route with `/` 
				     window.history.replaceState(null, null, '/');
					 window.location.href = 'https://www.ruv.de/';
				 }
			 	}).catch(error=>{
				 	console.error(error);
					window.history.replaceState(null, null, '/');
				 	window.location.href = 'https://www.ruv.de/'
			 	})
				 
		}	
		
	},[]);
	
	
	return  <SoftBox sx={{display:'flex', justifyContent:'center', alignItems:'center',height:'100vh'}}> 
	    		<Box sx={{ display: 'flex' }}>
				  <CircularProgress color="inherit"/>
				</Box> 
				{handleLogoutComponent()}
			</SoftBox> 

}



export default CallbackComponent;
